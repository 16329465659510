// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout-jeunesse'
import {
  received
} from '../components/layout.module.css'

// Step 2: Define your component
const AlivePage = () => {
  return (
	<Layout pageTitle="Alive!">
	  <form>
	  <article>
	    <h2>On the topic of aliveness</h2>
		<p><span className={received}><a href="https://www.patternlanguage.com/bookstore/timeless-way-of-building.html">The Timeless Way of Building</a> by Christopher Alexander:</span> This is the book that made me want to change my life. It's rather expensive, and hard to find at libraries, but if you can get your hands on a copy, it might just be a desert-island book.</p>
		<p><span className={received}><a href="https://www.patternlanguage.com/bookstore/pattern-language.html">A Pattern Language</a> by Christopher Alexander:</span> I barely touched on pattern languages, which is what Alexander is most known for. He used the The Quality Without a Name as his metric for whether a design pattern was intrinsically "alive" or not, and he collaborated with others to find the ones that were repeatable. This is his (enormous) collection of those patterns.</p> 
		<p> <span className={received}><a href="https://www.buildingbeauty.org/nature-of-order-lecture-series">The Nature of Order Lecture Series</a> based on the books by Christopher Alexander:</span> This is the only resource I haven't actually participated in all the way through, but it is the next thing on my list. There are pieces of it that are live that you must register for (all free), but there are many lectures that have been recorded and are available without registration.</p>
		<p> <span className={received}><a href="https://www.penguinrandomhouse.ca/books/55474/zen-of-seeing-by-frederick-franck/9780394719689">The Zen of Seeing</a> by Frederick Franck:</span> This is a book about drawing and about being present with what is really there. I fell in love with it not only because of the truth of it, but because it is entirely written by hand. It made me want to write everything on this site by hand (all of my original journals are hand-written), but after many failed attempts, I decided to hand-write only a small part.</p>
	  </article>
	  <article>
		<h2>Nurturing my aliveness (maybe yours, too?)</h2>
		<p><span className={received}><a href="https://www.theparisreview.org/fiction/7802/the-beyoglu-municipality-waste-management-orchestra-kenan-orhan">The Beyoğlu Municipality Waste Management Orchestra</a> by Kenan Orhan <em>(possible paywall)</em>:</span> I subscribed to The Paris Review print edition last year because I wanted more serendipitous occasions when I stumble on a poem or a short story that moves me deeply. This is one of them.</p>
		<p><span className={received}><a href="https://www.penguinrandomhouse.com/books/350817/we-have-always-lived-in-the-castle-by-shirley-jackson-afterword-by-jonathan-lethem/">We Have Always Lived in the Castle</a> by Shirley Jackson:</span> I was recently re-introduced to Shirley Jackson when she was referenced in a book by Alice Hoffman (another author who is surprising me with her powerful stories lately). You might remember Jackson from a high school English class...remember <em>The Lottery</em>? Anyway, wow. I could not put this creepy book down.</p>
		<p><span className={received}><a href="https://live.davidwhyte.com/">The Edge You Carry With You Lecture Series</a> by David Whyte <em>(live event, March 6th, 13th, and 20th, available for purchase):</em></span> I've taken several of David Whyte's lecture series' over the past year and a half, and I've enjoyed them so much. If you find yourself in need of artists to surround yourself with and be mentored by from afar, he is a good one.</p>
		<p><span className={received}><a href="https://www.penguinrandomhouse.ca/books/85611/the-remains-of-the-day-by-kazuo-ishiguro/9780345809322">The Remains of the Day</a> by Kazuo Ishiguro:</span> This book written from the perspective of an English butler surprised me with its subtle intensity and humor. I am now a huge Ishiguro fan and cannot stop reading his work.</p>
		<p><span className={received}><a href="https://www.penguinrandomhouse.com/books/318638/upstream-by-mary-oliver/">Upstream: Selected Essays</a> by Mary Oliver:</span> I didn't realize Mary Oliver wrote prose until recently. It has been speaking to me deeply as I'm learning to prioritize my art, even when it doesn't seem very important compared to everything else I need to do and everything everyone around me is doing.</p>
		<p><span className={received}><a href="https://www.penguinrandomhouse.com/books/622478/transcendent-kingdom-by-yaa-gyasi/">Transcendent Kingdom</a> by Yaa Gyasi:</span> This book was healing for me when I lost my brother. I felt a deep kinship with the character, and I'm grateful it showed up when it did.</p>
		<p><span className={received}><a href="https://www.maryruefle.com/marie.html">Marie</a> by Mary Ruefle:</span> Mary Ruefle is one of my favorite favorite poets, and I recently discovered she has several "erasure" books! Two are currently published on her website, and I especially loved this one. (Ruefle is also giving me the courage to be less available/online and not to fret about it; just look at her <a href="https://www.maryruefle.com/contact.html">contact page</a>.)</p>
		<p><span className={received}><a href="https://openroadmedia.com/ebook/the-house-by-the-sea/9781497646353">The House by the Sea</a> by May Sarton:</span> I've loved May Sarton's journals for a while now (Journal of a Solitude was the first one I'd read). I've felt very "inward" lately, and she encourages me that it's a good way to be. And also, some of us are just like that.</p>
		<p><span className={received}><a href="http://www.randomhousebooks.com/books/17051/">Dandelion Wine</a> by Ray Bradbury:</span> The beauty of Bradbury's writing astonishes me. I cried reading the first few pages. The sentences. The sentences! And of course the story is perfect. Looking forward to Something Wicked This Way Comes, which is the next in the series (I only just discovered it's a series!)</p>
		<p><span className={received}><a href="https://seasons.melanie-richards.com/">SEA — Seasons</a> by Melanie Richards:</span> I just discovered this lovely project about ways to appreciate the seasons in the Seattle area. I am in Eastern Washington, which is...east. But it still feels very relevant.</p>
		<p> <span className={received}><a href="https://www.andotherstories.org/the-luminous-novel/">The Luminous Novel</a> by Mario Levrero, translated by Annie McDermott:</span> Unbelievably, I stumbled upon this novel after I'd decided to go back through my journals and see if any of it was publishable. As I was typing out my very unpolished, maybe-boring just-for-me writing, I was beginning to think it was a dumb idea. And then I read this book and I knew I had to follow it through.</p>
		<p><span className={received}><a href="https://alicehoffman.com/books/the-world-that-we-knew/">The World That We Knew</a> by Alice Hoffman:</span> I recently read Hoffman's Practical Magic series and was so impressed that I now need to read everything she's ever written. Turns out she's written a lot. This one was both magical and deeply moving.</p>
		<p><span className={received}><a href="https://www.penguin.com.au/books/the-member-of-the-wedding-9780141182827">The Member of the Wedding</a> by Carson McCullers:</span> There's been a theme in the novels I've been reading lately. So many first-person narratives that are brilliantly and subtly written. This one is stunning.</p>
		<p><span className={received}><a href="https://bookshop.org/books/true-grit/9781590204597">True Grit</a> by Charles Portis:</span> I didn't figure myself a lover of classic westerns, but someone mentioned that the movie was excellent, and so of course I had to read the book first. It is so incredibly written (and ALIVE!), I know it will be one I go back to.</p>
		<p>If you made it this far, consider this paragraph the very bottom of your very last package. I hope you enjoyed your first Post Day, and that we will have many happy Post Days together in the future!</p>
		<p>Here's to your aliveness!</p>
		<p>—Sarah</p>
		</article>
	  </form>
	</Layout>
  )
}

// Step 3: Export your component
export default AlivePage
